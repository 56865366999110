import {useState} from 'react'

import {KTSVG} from '../../../../_metronic/helpers'
import {useIntl} from 'react-intl'
import {useAuth} from '../../auth'

const SelectClientDrawer = () => {
  const intl = useIntl()
  const {auth, changeClientKey} = useAuth()
  const [selectedAccess, setSelectedAccess] = useState<string>('')
  const [selectedAccessName, setSelectedAccessName] = useState<string>('')

  const handleChangeClient = (evt: React.ChangeEvent<HTMLSelectElement>) => {
    const client = auth?.clientAccess.find((item) => item.clientKey === evt.target.value)
    setSelectedAccess(client?.clientKey!)
    setSelectedAccessName(client?.name!)
  }

  const handleChangeOnClick = (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    evt.preventDefault()
    changeClientKey(selectedAccess, selectedAccessName)
  }

  return (
    <div
      id='kt_access'
      className='bg-white'
      data-kt-drawer='true'
      data-kt-drawer-name='access'
      data-kt-drawer-activate='true'
      data-kt-drawer-overlay='true'
      data-kt-drawer-width="{default:'300px', 'lg': '600px'}"
      data-kt-drawer-direction='end'
      data-kt-drawer-toggle='#kt_access_toggle'
      data-kt-drawer-close='#kt_access_close'
    >
      <div className='card shadow-none w-100 rounded-0'>
        <div className='card-header' id='kt_access_header'>
          <h3 className='card-title fw-bolder text-dark'>Select Client</h3>

          <div className='card-toolbar'>
            <button
              type='button'
              className='btn btn-sm btn-icon btn-active-light-primary me-n5'
              id='kt_access_close'
            >
              <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
            </button>
          </div>
        </div>
        <div className='card-body position-relative ' id='kt_access_body'>
          <div className='overflow-auto pb-5'>
            <div className='f'>
              <label className='form-label'>{intl.formatMessage({id: 'FORM.CLIENT'})}</label>
              <select
                aria-label='Select client'
                name='access'
                onChange={(evt) => handleChangeClient(evt)}
                className='form-select form-select-lg'
              >
                {auth?.clientAccess !== null &&
                  auth?.clientAccess?.map((item) => (
                    <option key={item.id} value={item.clientKey}>
                      {item.name}
                    </option>
                  ))}
              </select>
            </div>
            <button
              type='button'
              className='btn btn-primary btn-light mt-4'
              onClick={(evt) => handleChangeOnClick(evt)}
            >
              {intl.formatMessage({id: 'BUTTON.SELECT'})}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export {SelectClientDrawer}
