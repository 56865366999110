import { ItemSelect } from "./models/ItemSelect"

export const STATUS_CODE_NOT_CONTENT = 204

export const ROLE_ROOT: string = 'Root'
export const ROLE_ADMINISTRATOR: string = 'Administrator'
export const ROLE_OPERATOR: string = 'Operator'
export const ROLE_INSPECTOR: string = 'Inspector'

export const PARAMETER_FACTOR_EMISION: string = 'Factor de Emision'
export const PARAMETER_AREA_MEDICION: string = 'Area de Medicion'
export const PARAMETER_AREA_UTIL: string = 'Area Util'
export const PARAMETER_AREA_DESCRIPCION: string = 'Descripcion Area'
export const PARAMETER_SENSOR_FRECUENCIA_LECTURA: string = 'Frecuencia de Lectura'
export const PARAMETER_SENSOR_CANTIDAD_MUESTRAS_A_PROMEDIAR: string =
  'Numero Muestras para Promedio'

export const ROLES = [
  {value: ROLE_ROOT, display: 'Root'},
  {value: ROLE_ADMINISTRATOR, display: 'Administrator'},
  {value: ROLE_INSPECTOR, display: 'Inspector'},
  {value: ROLE_OPERATOR, display: 'Operator'},
]

export const CHUNK_TIME_UNIT_MINUTE: string = 'Minute'
export const CHUNK_TIME_UNIT_HOUR: string = 'Hour'

export const CHUNK_TIME_UNITS = [
  {value: CHUNK_TIME_UNIT_MINUTE, display: 'Minute'},
  {value: CHUNK_TIME_UNIT_HOUR, display: 'Hour'},
]

export const SENSOR_TYPE_CURRENT: string = 'current'
export const SENSOR_TYPE_ACTIVE_POWER: string = 'active power'

export const SENSOR_TYPES_FILTER = [
  {value: SENSOR_TYPE_CURRENT, display: 'Corriente'},
  {value: SENSOR_TYPE_ACTIVE_POWER, display: 'Potencia Activa'},
]

export const SENSOR_CONNECTOR_TYPES = [
  {value: 'Modbus', display: 'Modbus'},
  {value: 'I2C', display: 'I2C'},
]

export const SENSOR_I2C_PIN = [
  {value: 'Pin01', display: 'Pin01'},
  {value: 'Pin23', display: 'Pin23'},
]

export const SYNC_ORDER_STATE_ACTIVE: number = 1
export const SYNC_ORDER_STATE_CANCEL: number = 2
export const SYNC_ORDER_STATE_COMPLETE: number = 3

export const SYNC_ORDER_STATES: any = {
  1: 'Active',
  2: 'Cancel',
  3: 'Complete',
}

export const SENSOR_TYPE_DOOR: string = 'Door'

export const BASE_LINE_KIND_ELECTRICITY = 'Electricity'
export const BASE_LINE_KIND_WATER = 'Water'

export const MONTHS: ItemSelect[] = [
  { value: "1", display: 'Enero'} as ItemSelect,
  { value: "2", display: 'Febrero'} as ItemSelect,
  { value: "3", display: 'Marzo'} as ItemSelect,
  { value: "4", display: 'Abril'} as ItemSelect,
  { value: "5", display: 'Mayo'} as ItemSelect,
  { value: "6", display: 'Junio'} as ItemSelect,
  { value: "7", display: 'Julio'} as ItemSelect,
  { value: "8", display: 'Agosto'} as ItemSelect,
  { value: "9", display: 'Septiembre'} as ItemSelect,
  { value: "10", display: 'Octubre'} as ItemSelect,
  { value: "11", display: 'Noviembre'} as ItemSelect,
  { value: "12", display: 'Diciembre'} as ItemSelect,
]

export function helpFunctions() {
  const randomHexColor = () => {
    let n = Math.floor(Math.random() * 16777215).toString(16)
    return '#' + n
  }

  const datetimeFormat = (value: Date) => {
    const year = value.getFullYear()
    const month = value.getMonth() + 1
    const day = value.getDate()
    const hour = value.getHours()
    const minute = value.getMinutes()
    const second = value.getSeconds()

    return `${year}-${month}-${day} ${hour}:${minute}:${second}`
  }

  const dateFormat = (value: Date) => {
    const year = value.getFullYear()
    const month = value.getMonth() + 1
    const day = value.getDate()

    return `${year}-${month}-${day}`
  }

  const roundDecimals = (value: number, decimalPlaces:number = 2) => {
    const factor = Math.pow(10, decimalPlaces);
    return Math.round((value + Number.EPSILON) * factor) / factor;
  }
  const firstLetterLower = (value: string) => value.charAt(0).toLowerCase() + value.slice(1)

  const getMonthName = (month: number) => {
    const monthNames = [
      'Enero',
      'Febrero',
      'Marzo',
      'Abril',
      'Mayo',
      'Junio',
      'Julio',
      'Agosto',
      'Septiembre',
      'Octubre',
      'Noviembre',
      'Diciembre',
    ]

    // Asegúrate de que el valor de `month` sea válido (entre 1 y 12)
    if (month >= 1 && month <= 12) {
      return monthNames[month - 1]
    } else {
      return '' // Otra opción es lanzar un error o devolver un valor predeterminado en caso de un mes inválido.
    }
  }

  return {
    randomHexColor,
    datetimeFormat,
    dateFormat,
    firstLetterLower,
    getMonthName,
    roundDecimals,
  }
}
