import {FC, useState, createContext} from 'react'
import {WithChildren} from '../../../../_metronic/helpers'

type SensorPaginateModel = {
  page: number
  setPage: React.Dispatch<React.SetStateAction<number>>
  pageSize: number
  setPageSize: React.Dispatch<React.SetStateAction<number>>
}

const initSensorPaginateState = {
  page: 1,
  setPage: () => {},
  pageSize: 10,
  setPageSize: () => {},
}

const SensorPaginateContext = createContext<SensorPaginateModel>(initSensorPaginateState)

const SensorPaginateProvider: FC<WithChildren> = ({children}) => {
  const [page, setPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(10)

  const valueModel: SensorPaginateModel = {
    page,
    setPage,
    pageSize,
    setPageSize,
  }

  return (
    <SensorPaginateContext.Provider value={valueModel}>{children}</SensorPaginateContext.Provider>
  )
}

export {SensorPaginateContext, SensorPaginateProvider}
