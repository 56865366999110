/* eslint-disable react/jsx-no-target-blank */
import {useIntl} from 'react-intl'
import {AsideMenuItem} from './AsideMenuItem'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {useAuth} from '../../../../app/modules/auth'
import {ROLE_ADMINISTRATOR, ROLE_INSPECTOR, ROLE_ROOT} from '../../../../app/common/Utils'

export function AsideMenuMain() {
  const intl = useIntl()
  const {currentUser} = useAuth()

  return (
    <>
      {currentUser?.role !== ROLE_INSPECTOR ? (
        <>
          <div className='menu-item'>
            <div className='menu-content pt-8 pb-2'>
              <span className='menu-section fw-bold fs-7 ls-1'>
                {intl.formatMessage({id: 'TITLE.MODULE_DIAGNOSTIC'})}
              </span>
            </div>
          </div>
          <AsideMenuItemWithSub
            to='/pages/charts'
            title={intl.formatMessage({id: 'MENU.CHARTS'})}
            icon='/media/icons/duotune/graphs/gra003.svg'
            fontIcon='bi-person'
          >
            {/* 
        <AsideMenuItem
          to='/pages/dynamicchart'
          icon='/media/icons/duotune/graphs/gra011.svg'
          title={intl.formatMessage({id: 'MENU.DYNAMICCHART'})}
          fontIcon='bi-app-indicator'
        />*/}
            <AsideMenuItem
              to='/pages/dynamicchartgroup'
              title={intl.formatMessage({id: 'MENU.DYNAMICCHARTGROUP'})}
              hasBullet={true}
            />
            <AsideMenuItem
              to='/pages/detailedconsumptionchart'
              title={intl.formatMessage({id: 'MENU.CIRCUITPOWERBYPHASES'})}
              hasBullet={true}
            />
            <AsideMenuItem
              to='/pages/generalcurrentbyphaseschart'
              title={intl.formatMessage({id: 'MENU.GENERALCURRENTCHART'})}
              hasBullet={true}
            />
            <AsideMenuItem
              to='/pages/generalpowerbyphaseschart'
              title={intl.formatMessage({id: 'MENU.GENERALPOWERCHART'})}
              hasBullet={true}
            />
            <AsideMenuItem
              to='/pages/generalcomsuptionbycircuitchart'
              title={intl.formatMessage({id: 'MENU.GENERALCIRCUITCHART'})}
              hasBullet={true}
            />
            <AsideMenuItem
              to='/pages/generalenergyweeksmonthspage'
              title={intl.formatMessage({id: 'MENU.GENERALENERGYWEEKSMONTHSCHART'})}
              hasBullet={true}
            />
          </AsideMenuItemWithSub>
          <AsideMenuItemWithSub
            to='/pages/reportes'
            title={intl.formatMessage({id: 'MENU.REPORTS'})}
            icon='/media/icons/duotune/coding/cod002.svg'
            fontIcon='bi-person'
          >
            <AsideMenuItem
              to='/pages/baselinereport'
              title={intl.formatMessage({id: 'MENU.BASELINE'})}
              hasBullet={true}
            />
            <AsideMenuItem
              to='/pages/informediario'
              title={intl.formatMessage({id: 'MENU.DAILYREPORT'})}
              hasBullet={true}
            />
            <AsideMenuItem
              to='/pages/comportamientodemandasemanal'
              title={intl.formatMessage({id: 'MENU.DEMANDBEHAVIOURWEEKLY'})}
              hasBullet={true}
            />
            <AsideMenuItem
              to='/pages/comportamientodemandamensual'
              title={intl.formatMessage({id: 'MENU.DEMANDBEHAVIOURMONTHLY'})}
              hasBullet={true}
            />
            <AsideMenuItem
              to='/pages/reportecuartofrio'
              title={intl.formatMessage({id: 'MENU.COLDCHAMBERS'})}
              hasBullet={true}
            />
            <AsideMenuItem
              to='/pages/exportdatatoexcel'
              title={intl.formatMessage({id: 'MENU.DOWNLOADDATATOEXCEL'})}
              hasBullet={true}
            />
          </AsideMenuItemWithSub>
          <div className='menu-item'>
            <div className='menu-content pt-8 pb-2'>
              <span className='menu-section fw-bold fs-7 ls-1'>
                {intl.formatMessage({id: 'TITLE.MODULE_SUPERVISION'})}
              </span>
            </div>
          </div>
          <AsideMenuItem
            to='/pages/dashboardpage'
            icon='/media/icons/duotune/general/gen001.svg'
            title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
            fontIcon='bi-app-indicator'
          />
          <AsideMenuItemWithSub
            to='/pages/productionchart'
            title={intl.formatMessage({id: 'MENU.PRODUCTION'})}
            icon='/media/icons/duotune/ecommerce/ecm010.svg'
            fontIcon='bi-person'
          >
            <AsideMenuItem
              to='/pages/productionchart'
              title={intl.formatMessage({id: 'MENU.ANALYZE'})}
              hasBullet={true}
            />
            <AsideMenuItem
              to='/pages/productionimport'
              title={intl.formatMessage({id: 'MENU.IMPORT'})}
              hasBullet={true}
            />
          </AsideMenuItemWithSub>
          <div className='menu-item'>
            <div className='menu-content pt-8 pb-2'>
              <span className='menu-section fw-bold fs-7 ls-1'>
                {intl.formatMessage({id: 'TITLE.MODULE_PREDICTION'})}
              </span>
            </div>
          </div>
          <AsideMenuItem
            to='/pages/navigation'
            icon='/media/icons/duotune/abstract/abs016.svg'
            title={intl.formatMessage({id: 'MENU.NAVIGATION'})}
            fontIcon='bi-app-indicator'
          />
          <AsideMenuItem
            to='/pages/prediction'
            icon='/media/icons/duotune/abstract/abs016.svg'
            title={intl.formatMessage({id: 'MENU.PREDICTION'})}
            fontIcon='bi-app-indicator'
          />
          <AsideMenuItem
            to='/pages/validation'
            icon='/media/icons/duotune/abstract/abs016.svg'
            title={intl.formatMessage({id: 'MENU.VALIDATION'})}
            fontIcon='bi-app-indicator'
          />
          {/* <AsideMenuItem
        to='/pages/sensorrealtime'
        icon='/media/icons/duotune/general/gen013.svg'
        title={intl.formatMessage({id: 'MENU.REALTIMEDASHBOARD'})}
        fontIcon='bi-app-indicator'
      />*/}
        </>
      ) : (
        <></>
      )}

      {currentUser?.role === ROLE_ROOT || currentUser?.role === ROLE_INSPECTOR ? (
        <>
          <div className='menu-item'>
            <div className='menu-content pt-8 pb-2'>
              <span className='menu-section fw-bold fs-7 ls-1'>
                {intl.formatMessage({id: 'MENU.ENERGYINSPECTION'})}
              </span>
            </div>
          </div>
          <AsideMenuItemWithSub
            to='/pages/clientemetadatos'
            title={intl.formatMessage({id: 'MENU.ENERGYINSPECTION'})}
            icon='/media/icons/duotune/files/fil012.svg'
            fontIcon='bi-person'
          >
            <AsideMenuItem
              to='/pages/clientemetadatos'
              title='Cliente Metadatos'
              hasBullet={true}
            />
            <AsideMenuItem
              to='/pages/general_architectural_analysis'
              title='General architectural analysis'
              hasBullet={true}
            />
            <AsideMenuItem
              to='/pages/general_architectural_analysis_in_areas'
              title='General architectural analysis in areas'
              hasBullet={true}
            />
            <AsideMenuItem
              to='/pages/local_technical_visit'
              title='Local technical visit'
              hasBullet={true}
            />
            <AsideMenuItem
              to='/pages/technical_visit_in_areas'
              title='Technical visit in areas'
              hasBullet={true}
            />
            <AsideMenuItem
              to='/pages/verification_and_assembly_of_measurement_system'
              title='Verification and assembly of measurement system'
              hasBullet={true}
            />
            <AsideMenuItem
              to='/pages/energy_accounting'
              title='Energy accounting'
              hasBullet={true}
            />
            <AsideMenuItem
              to='/pages/thermographic_inspection'
              title='Thermographic inspection'
              hasBullet={true}
            />
            <AsideMenuItem
              to='/pages/check_of_measurement_kits'
              title='Check of measurement kits'
              hasBullet={true}
            />
            <AsideMenuItem
              to='/pages/testcomponentes'
              title='componentes pruebas'
              hasBullet={true}
            />
          </AsideMenuItemWithSub>
        </>
      ) : (
        <></>
      )}
      {currentUser?.role === ROLE_ROOT || currentUser?.role === ROLE_ADMINISTRATOR ? (
        <AsideMenuItem
          to='/pages/sensorerrorpaginate'
          icon='/media/icons/duotune/files/fil007.svg'
          title={intl.formatMessage({id: 'MENU.SENSORERROR'})}
          fontIcon='bi-app-indicator'
        />
      ) : (
        <></>
      )}
      {currentUser?.hasColdChambers || currentUser?.role === ROLE_ROOT ? (
        <AsideMenuItemWithSub
          to='/pages/inventorysummary'
          title={intl.formatMessage({id: 'MENU.INVENTORY'})}
          icon='/media/icons/duotune/finance/fin007.svg'
          fontIcon='bi-person'
        >
          <AsideMenuItem
            to='/pages/inventorysummary'
            title={intl.formatMessage({id: 'MENU.SUMMARY'})}
            hasBullet={true}
          />
          <AsideMenuItem
            to='/pages/inventorydetail'
            title={intl.formatMessage({id: 'MENU.DETAIL'})}
            hasBullet={true}
          />
        </AsideMenuItemWithSub>
      ) : (
        <></>
      )}
      {currentUser?.role === ROLE_ADMINISTRATOR || currentUser?.role === ROLE_ROOT ? (
        <div className='menu-item'>
          <div className='menu-content pt-8 pb-2'>
            <span className='menu-section fw-bold fs-7 ls-1'>
              {intl.formatMessage({id: 'MENU.SETTTINGS'})}
            </span>
          </div>
        </div>
      ) : (
        <></>
      )}

      {currentUser?.role === ROLE_ROOT ? (
        <AsideMenuItem
          to='/pages/clientpaginate'
          icon='/media/icons/duotune/communication/com006.svg'
          title={intl.formatMessage({id: 'MENU.CLIENTPAGINATE'})}
          fontIcon='bi-app-indicator'
        />
      ) : (
        <></>
      )}
      {currentUser?.role === ROLE_ROOT ? (
        <>
          <AsideMenuItem
            to='/pages/sensorpaginate'
            icon='/media/icons/duotune/electronics/elc003.svg'
            title={intl.formatMessage({id: 'MENU.SENSORPAGINATE'})}
            fontIcon='bi-app-indicator'
          />
          <AsideMenuItem
            to='/pages/circuitpaginate'
            icon='/media/icons/duotune/communication/com001.svg'
            title={intl.formatMessage({id: 'MENU.CIRCUITPAGINATE'})}
            fontIcon='bi-app-indicator'
          />
          <AsideMenuItem
            to='/pages/devicepaginate'
            icon='/media/icons/duotune/abstract/abs041.svg'
            title={intl.formatMessage({id: 'MENU.DEVICE'})}
            fontIcon='bi-app-indicator'
          />
        </>
      ) : (
        <> </>
      )}

      {currentUser?.role === ROLE_ROOT || currentUser?.role === ROLE_ADMINISTRATOR ? (
        <>
          <AsideMenuItem
            to='/pages/productiontemplate'
            icon='/media/icons/duotune/abstract/abs010.svg'
            title={intl.formatMessage({id: 'MENU.PRODUCTION_IMPORT_SETTINGS'})}
            fontIcon='bi-app-indicator'
          />
          <AsideMenuItem
            to='/pages/baselinepage'
            icon='/media/icons/duotune/graphs/gra002.svg'
            title={intl.formatMessage({id: 'MENU.BASELINE'})}
            fontIcon='bi-app-indicator'
          />
          <AsideMenuItem
            to='/pages/circuitcategorypaginate'
            icon='/media/icons/duotune/electronics/elc003.svg'
            title={intl.formatMessage({id: 'TITLE.CIRCUITCATEGORYPAGINATION'})}
            fontIcon='bi-app-indicator'
          />
        </>
      ) : (
        <></>
      )}
      {currentUser?.hasColdChambers || currentUser?.role === ROLE_ROOT ? (
        <AsideMenuItemWithSub
          to='/pages/inventorymenu'
          title={intl.formatMessage({id: 'MENU.INVENTORY'})}
          icon='/media/icons/duotune/finance/fin007.svg'
          fontIcon='bi-person'
        >
          <AsideMenuItem
            to='/pages/productpaginate'
            icon='/media/icons/duotune/finance/fin007.svg'
            title={intl.formatMessage({id: 'MENU.PRODUCTPAGINATE'})}
            fontIcon='bi-app-indicator'
          />
          <AsideMenuItem
            to='/pages/coldchamberpaginate'
            icon='/media/icons/duotune/general/gen017.svg'
            title={intl.formatMessage({id: 'MENU.COLDCHAMBERS'})}
            fontIcon='bi-app-indicator'
          />
          <AsideMenuItem
            to='/pages/warehousepaginate'
            icon='/media/icons/duotune/finance/fin001.svg'
            title={intl.formatMessage({id: 'MENU.WAREHOUSEPAGINATE'})}
            fontIcon='bi-app-indicator'
          />
          <AsideMenuItem
            to='/pages/unitmeasurementpaginate'
            icon='/media/icons/duotune/abstract/abs010.svg'
            title={intl.formatMessage({id: 'MENU.UNITMEASUREMENTPAGINATE'})}
            fontIcon='bi-app-indicator'
          />
        </AsideMenuItemWithSub>
      ) : (
        <></>
      )}

      {currentUser?.role === ROLE_ROOT || currentUser?.role === ROLE_ADMINISTRATOR ? (
        <>
          <AsideMenuItemWithSub
            to='/pages/clientparameter'
            title={intl.formatMessage({id: 'MENU.PARAMETERS'})}
            icon='/media/icons/duotune/files/fil001.svg'
            fontIcon='bi-person'
          >
            {currentUser?.role === ROLE_ROOT ? (
              <AsideMenuItem
                to='/pages/clientparameter'
                icon='/media/icons/duotune/general/gen017.svg'
                title={intl.formatMessage({id: 'MENU.CLIENTPARAMETER'})}
                fontIcon='bi-app-indicator'
              />
            ) : (
              <></>
            )}
            <AsideMenuItem
              to='/pages/yearparameter'
              icon='/media/icons/duotune/general/gen017.svg'
              title={intl.formatMessage({id: 'MENU.YEARPARAMETER'})}
              fontIcon='bi-app-indicator'
            />
          </AsideMenuItemWithSub>
        </>
      ) : (
        <></>
      )}
    </>
  )
}
