import {EnableSidebar, PageTitle} from '../../../_metronic/layout/core'

const DashboardPage = () => <></>

const DashboardWrapper = () => {
  return (
    <EnableSidebar>
      <PageTitle description='' breadcrumbs={[]}></PageTitle>
      <DashboardPage />
    </EnableSidebar>
  )
}

export {DashboardWrapper}
