import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {DisableSidebar} from '../../_metronic/layout/core'
import {WithChildren} from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import {useAuth} from '../modules/auth'
import ProtectedRoute from './ProtectedRoute'
import {ROLE_ADMINISTRATOR, ROLE_INSPECTOR, ROLE_OPERATOR, ROLE_ROOT} from '../common/Utils'

const PrivateRoutes = () => {
  const {currentUser} = useAuth()

  const ClientPaginate = lazy(() => import('../modules/client/ClientPaginatePage'))
  const ClientFormPage = lazy(() => import('../modules/client/ClientFormPage'))
  const ProductPaginate = lazy(() => import('../modules/inventory/ProductPaginatePage'))
  const ProductFormPage = lazy(() => import('../modules/inventory/ProductFormPage'))
  const WarehousePaginatePage = lazy(() => import('../modules/inventory/WarehousePaginatePage'))
  const WarehouseFormPage = lazy(() => import('../modules/inventory/WarehouseFormPage'))
  const CircuitCategoryPaginatePage = lazy(
    () => import('../modules/sensor/CircuitCategoryPaginatePage')
  )
  const CircuitPaginatePage = lazy(() => import('../modules/sensor/CircuitPaginatePage'))
  const CircuitCategoryFormPage = lazy(() => import('../modules/sensor/CircuitCategoryFormPage'))
  const CircuitFormPage = lazy(() => import('../modules/sensor/CircuitFormPage'))
  const DevicePaginatePage = lazy(() => import('../modules/sensor/DevicePaginatePage'))
  const DeviceFormPage = lazy(() => import('../modules/sensor/DeviceFormPage'))
  const ColdChamberPaginatePage = lazy(() => import('../modules/inventory/ColdChamberPaginatePage'))
  const ColdChamberFormPage = lazy(() => import('../modules/inventory/ColdChamberFormPage'))
  const SensorPaginatePage = lazy(() => import('../modules/sensor/SensorPaginatePage'))
  const SensorFormPage = lazy(() => import('../modules/sensor/SensorFormPage'))
  const SensorRealTimePage = lazy(() => import('../modules/sensor/SensorRealTimePage'))
  const SyncOrderPaginatePage = lazy(() => import('../modules/sensor/SyncOrderPaginatePage'))
  const SensorErrorPaginatePage = lazy(() => import('../modules/sensor/SensorErrorPaginatePage'))
  const DynamicChartPage = lazy(() => import('../modules/charts/DynamicChartPage'))
  const DynamicChartGroupPage = lazy(() => import('../modules/charts/DynamicChartGroupPage'))
  const GeneralCurrentByPhasesPage = lazy(
    () => import('../modules/charts/GeneralCurrentByPhasesPage')
  )
  const GeneralPowerByPhasesPage = lazy(() => import('../modules/charts/GeneralPowerByPhasesPage'))
  const GeneralComsuptionByCircuitPage = lazy(
    () => import('../modules/charts/GeneralComsuptionByCircuitPage')
  )
  const GeneralEnergyWeeksMonthsPage = lazy(
    () => import('../modules/charts/GeneralEnergyWeeksMonthsPage')
  )
  const DashboardPage = lazy(() => import('../modules/dashboard/DashboardPage'))

  const InventorySummary = lazy(() => import('../modules/inventory/InventorySummary'))
  const InventoryDetail = lazy(() => import('../modules/inventory/InventoryDetail'))
  const UnitMeasurementPaginatePage = lazy(
    () => import('../modules/inventory/UnitMeasurementPaginatePage')
  )
  const UnitMeasurementFormPage = lazy(() => import('../modules/inventory/UnitMeasurementFormPage'))
  const UserPaginatePage = lazy(() => import('../modules/security/UserPaginatePage'))
  const UserFormPage = lazy(() => import('../modules/security/UserFormPage'))
  const UserProfilePage = lazy(() => import('../modules/security/UserProfilePage'))
  /* ----------------- Energy inspection ---------------------*/
  const ClienteMetadatosPage = lazy(
    () => import('../modules/energy_inspection/ClienteMetadatosPage')
  )
  const TestComponentPage = lazy(() => import('../modules/energy_inspection/TestComponentPage'))

  const LocalTechnicalVisitPage = lazy(
    () => import('../modules/energy_inspection/LocalTechnicalVisitPage')
  )

  const TechnicalVisitInAreas = lazy(
    () => import('../modules/energy_inspection/TechnicalVisitInAreas')
  )
  const ThermographicInspection = lazy(
    () => import('../modules/energy_inspection/ThermographicInspection')
  )
  const EnergyAccounting = lazy(() => import('../modules/energy_inspection/EnergyAccounting'))
  const VerificationAndAssemblyMeasurementSysPage = lazy(
    () => import('../modules/energy_inspection/VerificationAndAssemblyMeasurementSysPage')
  )
  const GeneralArchitecturalAnalysisPage = lazy(
    () => import('../modules/energy_inspection/GeneralArchitecturalAnalysisPage')
  )

  const TechnicalArchitecturaAnalysisInAreasPage = lazy(
    () => import('../modules/energy_inspection/TechnicalArchitecturaAnalysisInAreasPage')
  )

  const CheckMeasurementKitsPage = lazy(
    () => import('../modules/energy_inspection/CheckMeasurementKitsPage')
  )
  const YearParametersPage = lazy(() => import('../modules/parameters/YearParametersPage'))
  const ParametersPage = lazy(() => import('../modules/parameters/ParametersPage'))

  const ComportamientoDemandaSemanalPage = lazy(
    () => import('../modules/reports/ComportamientoDemandaSemanalPage')
  )
  const DiarioPage = lazy(() => import('../modules/reports/DiarioPage'))
  const ComportamientoDemandaMensualPage = lazy(
    () => import('../modules/reports/ComportamientoDemandaMensualPage')
  )
  const ReporteCuartoFrioPage = lazy(() => import('../modules/reports/ReporteCuartoFrioPage'))

  const CircuitPowerByPhasesPage = lazy(() => import('../modules/charts/CircuitPowerByPhasesPage'))
  const ExportDataToExcelPage = lazy(() => import('../modules/reports/ExportDataToExcelPage'))
  const BaseLinePage = lazy(() => import('../modules/energy_inspection/BaseLinePage'))
  const BaseLineReportPage = lazy(() => import('../modules/reports/BaseLineReportPage'))
  const PredictionPage = lazy(() => import('../modules/prediction/PredictionPage'))
  const ProductionTemplatePage = lazy(() => import('../modules/production/ProductionTemplatePage'))
  const ProductionImportPage = lazy(() => import('../modules/production/ProductionImportPage'))
  const ProductionChartPage = lazy(() => import('../modules/production/ProductionChartPage'))
  const ModelNavigationPage = lazy(() => import('../modules/prediction/ModelNavigationPage'))
  const ModelDetailsPage = lazy(() => import('../modules/prediction/ModelDetailsPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/pages/dashboardpage' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='builder' element={<BuilderPageWrapper />} />
        {/* Lazy Modules */}
        <Route
          path='pages/dashboardpage/*'
          element={
            <SuspensedView>
              <ProtectedRoute
                user={currentUser}
                validRoles={[ROLE_ROOT, ROLE_ADMINISTRATOR, ROLE_OPERATOR]}
              >
                <DashboardPage />
              </ProtectedRoute>
            </SuspensedView>
          }
        />
        <Route
          path='pages/clientparameter/*'
          element={
            <SuspensedView>
              <ProtectedRoute user={currentUser} validRoles={[ROLE_ROOT]}>
                <ParametersPage />
              </ProtectedRoute>
            </SuspensedView>
          }
        />
        <Route
          path='pages/prediction/*'
          element={
            <SuspensedView>
              <ProtectedRoute
                user={currentUser}
                validRoles={[ROLE_ROOT, ROLE_ADMINISTRATOR, ROLE_OPERATOR]}
              >
                <PredictionPage />
              </ProtectedRoute>
            </SuspensedView>
          }
        />
        <Route
          path='pages/navigation/*'
          element={
            <SuspensedView>
              <ProtectedRoute
                user={currentUser}
                validRoles={[ROLE_ROOT, ROLE_ADMINISTRATOR, ROLE_OPERATOR]}
              >
                <ModelNavigationPage />
              </ProtectedRoute>
            </SuspensedView>
          }
        />
        <Route
          path='pages/navigation/model-details/:modelId'
          element={
            <SuspensedView>
              <ProtectedRoute
                user={currentUser}
                validRoles={[ROLE_ROOT, ROLE_ADMINISTRATOR, ROLE_OPERATOR]}
              >
                <ModelDetailsPage />
              </ProtectedRoute>
            </SuspensedView>
          }
        />
        <Route
          path='pages/productiontemplate'
          element={
            <SuspensedView>
              <ProtectedRoute
                user={currentUser}
                validRoles={[ROLE_ROOT, ROLE_ADMINISTRATOR, ROLE_OPERATOR]}
              >
                <ProductionTemplatePage />
              </ProtectedRoute>
            </SuspensedView>
          }
        />
        <Route
          path='pages/productionimport'
          element={
            <SuspensedView>
              <ProtectedRoute
                user={currentUser}
                validRoles={[ROLE_ROOT, ROLE_ADMINISTRATOR, ROLE_OPERATOR]}
              >
                <ProductionImportPage />
              </ProtectedRoute>
            </SuspensedView>
          }
        />
        <Route
          path='pages/productionchart'
          element={
            <SuspensedView>
              <ProtectedRoute
                user={currentUser}
                validRoles={[ROLE_ROOT, ROLE_ADMINISTRATOR, ROLE_OPERATOR]}
              >
                <ProductionChartPage />
              </ProtectedRoute>
            </SuspensedView>
          }
        />
        <Route
          path='pages/yearparameter/*'
          element={
            <SuspensedView>
              <ProtectedRoute user={currentUser} validRoles={[ROLE_ROOT, ROLE_ADMINISTRATOR]}>
                <YearParametersPage />
              </ProtectedRoute>
            </SuspensedView>
          }
        />
        {/* -----------------Test components page ---------------------*/}
        <Route
          path='pages/testcomponentes/*'
          element={
            <SuspensedView>
              <ProtectedRoute user={currentUser} validRoles={[ROLE_ROOT, ROLE_INSPECTOR]}>
                <TestComponentPage />
              </ProtectedRoute>
            </SuspensedView>
          }
        />
        {/* -----------------Test components page ---------------------*/}
        {/* ----------------- Energy Inspection ---------------------*/}
        <Route
          path='pages/clientemetadatos/*'
          element={
            <SuspensedView>
              <ProtectedRoute user={currentUser} validRoles={[ROLE_ROOT, ROLE_INSPECTOR]}>
                <ClienteMetadatosPage />
              </ProtectedRoute>
            </SuspensedView>
          }
        />
        <Route
          path='pages/general_architectural_analysis/*'
          element={
            <SuspensedView>
              <ProtectedRoute user={currentUser} validRoles={[ROLE_ROOT, ROLE_INSPECTOR]}>
                <GeneralArchitecturalAnalysisPage />
              </ProtectedRoute>
            </SuspensedView>
          }
        />

        <Route
          path='pages/general_architectural_analysis_in_areas/*'
          element={
            <SuspensedView>
              <ProtectedRoute user={currentUser} validRoles={[ROLE_ROOT, ROLE_INSPECTOR]}>
                <TechnicalArchitecturaAnalysisInAreasPage />
              </ProtectedRoute>
            </SuspensedView>
          }
        />
        <Route
          path='pages/technical_visit_in_areas/*'
          element={
            <SuspensedView>
              <ProtectedRoute user={currentUser} validRoles={[ROLE_ROOT, ROLE_INSPECTOR]}>
                <TechnicalVisitInAreas></TechnicalVisitInAreas>
              </ProtectedRoute>
            </SuspensedView>
          }
        />

        <Route
          path='pages/local_technical_visit/*'
          element={
            <SuspensedView>
              <ProtectedRoute user={currentUser} validRoles={[ROLE_ROOT, ROLE_INSPECTOR]}>
                <LocalTechnicalVisitPage />
              </ProtectedRoute>
            </SuspensedView>
          }
        />
        <Route
          path='pages/verification_and_assembly_of_measurement_system/*'
          element={
            <SuspensedView>
              <ProtectedRoute user={currentUser} validRoles={[ROLE_ROOT, ROLE_INSPECTOR]}>
                <VerificationAndAssemblyMeasurementSysPage />
              </ProtectedRoute>
            </SuspensedView>
          }
        />
        <Route
          path='pages/energy_accounting/*'
          element={
            <SuspensedView>
              <ProtectedRoute user={currentUser} validRoles={[ROLE_ROOT, ROLE_INSPECTOR]}>
                <EnergyAccounting />
              </ProtectedRoute>
            </SuspensedView>
          }
        />
        <Route
          path='pages/thermographic_inspection/*'
          element={
            <SuspensedView>
              <ProtectedRoute user={currentUser} validRoles={[ROLE_ROOT, ROLE_INSPECTOR]}>
                <ThermographicInspection />
              </ProtectedRoute>
            </SuspensedView>
          }
        />
        <Route
          path='pages/check_of_measurement_kits/*'
          element={
            <SuspensedView>
              <ProtectedRoute user={currentUser} validRoles={[ROLE_ROOT, ROLE_INSPECTOR]}>
                <CheckMeasurementKitsPage />
              </ProtectedRoute>
            </SuspensedView>
          }
        />
        <Route
          path='pages/baselinepage/*'
          element={
            <SuspensedView>
              <ProtectedRoute user={currentUser} validRoles={[ROLE_ROOT, ROLE_ADMINISTRATOR]}>
                <BaseLinePage />
              </ProtectedRoute>
            </SuspensedView>
          }
        />
        {/* ----------------- Reports ---------------------*/}
        <Route
          path='pages/baselinereport/*'
          element={
            <SuspensedView>
              <ProtectedRoute
                user={currentUser}
                validRoles={[ROLE_ROOT, ROLE_ADMINISTRATOR, ROLE_OPERATOR]}
              >
                <BaseLineReportPage />
              </ProtectedRoute>
            </SuspensedView>
          }
        />
        <Route
          path='pages/informediario/*'
          element={
            <SuspensedView>
              <ProtectedRoute
                user={currentUser}
                validRoles={[ROLE_ROOT, ROLE_ADMINISTRATOR, ROLE_OPERATOR]}
              >
                <DiarioPage />
              </ProtectedRoute>
            </SuspensedView>
          }
        />
        <Route
          path='pages/comportamientodemandasemanal/*'
          element={
            <SuspensedView>
              <ProtectedRoute
                user={currentUser}
                validRoles={[ROLE_ROOT, ROLE_ADMINISTRATOR, ROLE_OPERATOR]}
              >
                <ComportamientoDemandaSemanalPage />
              </ProtectedRoute>
            </SuspensedView>
          }
        />
        <Route
          path='pages/comportamientodemandamensual/*'
          element={
            <SuspensedView>
              <ProtectedRoute
                user={currentUser}
                validRoles={[ROLE_ROOT, ROLE_ADMINISTRATOR, ROLE_OPERATOR]}
              >
                <ComportamientoDemandaMensualPage />
              </ProtectedRoute>
            </SuspensedView>
          }
        />
        <Route
          path='pages/reportecuartofrio/*'
          element={
            <SuspensedView>
              <ProtectedRoute
                user={currentUser}
                validRoles={[ROLE_ROOT, ROLE_ADMINISTRATOR, ROLE_OPERATOR]}
              >
                <ReporteCuartoFrioPage />
              </ProtectedRoute>
            </SuspensedView>
          }
        />
        <Route
          path='pages/exportdatatoexcel/*'
          element={
            <SuspensedView>
              <ProtectedRoute
                user={currentUser}
                validRoles={[ROLE_ROOT, ROLE_ADMINISTRATOR, ROLE_OPERATOR]}
              >
                <ExportDataToExcelPage />
              </ProtectedRoute>
            </SuspensedView>
          }
        />
        <Route
          path='pages/clientpaginate/*'
          element={
            <SuspensedView>
              <ProtectedRoute user={currentUser} validRoles={[ROLE_ROOT]}>
                <ClientPaginate />
              </ProtectedRoute>
            </SuspensedView>
          }
        />
        <Route
          path='pages/clientform/*'
          element={
            <SuspensedView>
              <ProtectedRoute user={currentUser} validRoles={[ROLE_ROOT]}>
                <ClientFormPage />
              </ProtectedRoute>
            </SuspensedView>
          }
        />
        <Route
          path='pages/productpaginate/*'
          element={
            <SuspensedView>
              <ProtectedRoute user={currentUser} validRoles={[ROLE_ROOT, ROLE_ADMINISTRATOR]}>
                <ProductPaginate />
              </ProtectedRoute>
            </SuspensedView>
          }
        />
        <Route
          path='pages/productform/*'
          element={
            <SuspensedView>
              <ProtectedRoute user={currentUser} validRoles={[ROLE_ROOT, ROLE_ADMINISTRATOR]}>
                <ProductFormPage />
              </ProtectedRoute>
            </SuspensedView>
          }
        />
        <Route
          path='pages/warehousepaginate/*'
          element={
            <SuspensedView>
              <ProtectedRoute user={currentUser} validRoles={[ROLE_ROOT, ROLE_ADMINISTRATOR]}>
                <WarehousePaginatePage />
              </ProtectedRoute>
            </SuspensedView>
          }
        />
        <Route
          path='pages/warehouseform/*'
          element={
            <SuspensedView>
              <ProtectedRoute user={currentUser} validRoles={[ROLE_ROOT, ROLE_ADMINISTRATOR]}>
                <WarehouseFormPage />
              </ProtectedRoute>
            </SuspensedView>
          }
        />
        <Route
          path='pages/sensorpaginate/*'
          element={
            <SuspensedView>
              <ProtectedRoute user={currentUser} validRoles={[ROLE_ROOT, ROLE_ADMINISTRATOR]}>
                <SensorPaginatePage />
              </ProtectedRoute>
            </SuspensedView>
          }
        />
        <Route
          path='pages/sensorform/*'
          element={
            <SuspensedView>
              <ProtectedRoute user={currentUser} validRoles={[ROLE_ROOT, ROLE_ADMINISTRATOR]}>
                <SensorFormPage />
              </ProtectedRoute>
            </SuspensedView>
          }
        />
        <Route
          path='pages/sensorrealtime/*'
          element={
            <SuspensedView>
              <ProtectedRoute
                user={currentUser}
                validRoles={[ROLE_ROOT, ROLE_ADMINISTRATOR, ROLE_OPERATOR]}
              >
                <SensorRealTimePage />
              </ProtectedRoute>
            </SuspensedView>
          }
        />
        <Route
          path='pages/syncorderpaginate/*'
          element={
            <SuspensedView>
              <ProtectedRoute user={currentUser} validRoles={[ROLE_ROOT, ROLE_ADMINISTRATOR]}>
                <SyncOrderPaginatePage />
              </ProtectedRoute>
            </SuspensedView>
          }
        />
        <Route
          path='pages/sensorerrorpaginate/*'
          element={
            <SuspensedView>
              <ProtectedRoute user={currentUser} validRoles={[ROLE_ROOT, ROLE_ADMINISTRATOR]}>
                <SensorErrorPaginatePage />
              </ProtectedRoute>
            </SuspensedView>
          }
        />
        <Route
          path='pages/circuitcategorypaginate/*'
          element={
            <SuspensedView>
              <ProtectedRoute user={currentUser} validRoles={[ROLE_ROOT, ROLE_ADMINISTRATOR]}>
                <CircuitCategoryPaginatePage />
              </ProtectedRoute>
            </SuspensedView>
          }
        />
        <Route
          path='pages/circuitpaginate/*'
          element={
            <SuspensedView>
              <ProtectedRoute user={currentUser} validRoles={[ROLE_ROOT]}>
                <CircuitPaginatePage />
              </ProtectedRoute>
            </SuspensedView>
          }
        />
        <Route
          path='pages/devicepaginate/*'
          element={
            <SuspensedView>
              <ProtectedRoute user={currentUser} validRoles={[ROLE_ROOT]}>
                <DevicePaginatePage />
              </ProtectedRoute>
            </SuspensedView>
          }
        />
        <Route
          path='pages/coldchamberpaginate/*'
          element={
            <SuspensedView>
              <ProtectedRoute user={currentUser} validRoles={[ROLE_ROOT, ROLE_ADMINISTRATOR]}>
                <ColdChamberPaginatePage />
              </ProtectedRoute>
            </SuspensedView>
          }
        />
        <Route
          path='pages/coldchamberform/*'
          element={
            <SuspensedView>
              <ProtectedRoute user={currentUser} validRoles={[ROLE_ROOT, ROLE_ADMINISTRATOR]}>
                <ColdChamberFormPage />
              </ProtectedRoute>
            </SuspensedView>
          }
        />
        <Route
          path='pages/circuitcategoryform/*'
          element={
            <SuspensedView>
              <ProtectedRoute user={currentUser} validRoles={[ROLE_ROOT]}>
                <CircuitCategoryFormPage />
              </ProtectedRoute>
            </SuspensedView>
          }
        />
        <Route
          path='pages/circuitform/*'
          element={
            <SuspensedView>
              <ProtectedRoute user={currentUser} validRoles={[ROLE_ROOT]}>
                <CircuitFormPage />
              </ProtectedRoute>
            </SuspensedView>
          }
        />
        <Route
          path='pages/deviceform/*'
          element={
            <SuspensedView>
              <ProtectedRoute user={currentUser} validRoles={[ROLE_ROOT]}>
                <DeviceFormPage />
              </ProtectedRoute>
            </SuspensedView>
          }
        />
        <Route
          path='pages/parameterspaginate/*'
          element={
            <SuspensedView>
              <ProtectedRoute user={currentUser} validRoles={[ROLE_ROOT]}>
                <ParametersPage />
              </ProtectedRoute>
            </SuspensedView>
          }
        />
        <Route
          path='pages/unitmeasurementpaginate/*'
          element={
            <SuspensedView>
              <ProtectedRoute user={currentUser} validRoles={[ROLE_ROOT, ROLE_ADMINISTRATOR]}>
                <UnitMeasurementPaginatePage />
              </ProtectedRoute>
            </SuspensedView>
          }
        />
        <Route
          path='pages/unitmeasurementform/*'
          element={
            <SuspensedView>
              <ProtectedRoute user={currentUser} validRoles={[ROLE_ROOT, ROLE_ADMINISTRATOR]}>
                <UnitMeasurementFormPage />
              </ProtectedRoute>
            </SuspensedView>
          }
        />
        <Route
          path='pages/dynamicchart/*'
          element={
            <SuspensedView>
              <ProtectedRoute
                user={currentUser}
                validRoles={[ROLE_ROOT, ROLE_ADMINISTRATOR, ROLE_OPERATOR]}
              >
                <DynamicChartPage />
              </ProtectedRoute>
            </SuspensedView>
          }
        />
        <Route
          path='pages/dynamicchartgroup/*'
          element={
            <SuspensedView>
              <ProtectedRoute
                user={currentUser}
                validRoles={[ROLE_ROOT, ROLE_ADMINISTRATOR, ROLE_OPERATOR]}
              >
                <DynamicChartGroupPage />
              </ProtectedRoute>
            </SuspensedView>
          }
        />
        {/* --------------------------------------------------------*/}
        <Route
          path='pages/generalcurrentbyphaseschart/*'
          element={
            <SuspensedView>
              <ProtectedRoute
                user={currentUser}
                validRoles={[ROLE_ROOT, ROLE_ADMINISTRATOR, ROLE_OPERATOR]}
              >
                <GeneralCurrentByPhasesPage />
              </ProtectedRoute>
            </SuspensedView>
          }
        />
        <Route
          path='pages/generalpowerbyphaseschart/*'
          element={
            <SuspensedView>
              <ProtectedRoute
                user={currentUser}
                validRoles={[ROLE_ROOT, ROLE_ADMINISTRATOR, ROLE_OPERATOR]}
              >
                <GeneralPowerByPhasesPage />
              </ProtectedRoute>
            </SuspensedView>
          }
        />
        <Route
          path='pages/generalcomsuptionbycircuitchart/*'
          element={
            <SuspensedView>
              <ProtectedRoute
                user={currentUser}
                validRoles={[ROLE_ROOT, ROLE_ADMINISTRATOR, ROLE_OPERATOR]}
              >
                <GeneralComsuptionByCircuitPage />
              </ProtectedRoute>
            </SuspensedView>
          }
        />
        <Route
          path='pages/generalenergyweeksmonthspage/*'
          element={
            <SuspensedView>
              <ProtectedRoute
                user={currentUser}
                validRoles={[ROLE_ROOT, ROLE_ADMINISTRATOR, ROLE_OPERATOR]}
              >
                <GeneralEnergyWeeksMonthsPage />
              </ProtectedRoute>
            </SuspensedView>
          }
        />
        <Route
          path='pages/detailedconsumptionchart/*'
          element={
            <SuspensedView>
              <ProtectedRoute
                user={currentUser}
                validRoles={[ROLE_ROOT, ROLE_ADMINISTRATOR, ROLE_OPERATOR]}
              >
                <CircuitPowerByPhasesPage />
              </ProtectedRoute>
            </SuspensedView>
          }
        />
        {/*-----------------------------------------------------------*/}
        <Route
          path='pages/inventorysummary/*'
          element={
            <SuspensedView>
              <ProtectedRoute user={currentUser} validRoles={[ROLE_ROOT, ROLE_ADMINISTRATOR]}>
                <InventorySummary />
              </ProtectedRoute>
            </SuspensedView>
          }
        />
        <Route
          path='pages/inventorydetail/*'
          element={
            <SuspensedView>
              <ProtectedRoute user={currentUser} validRoles={[ROLE_ROOT, ROLE_ADMINISTRATOR]}>
                <InventoryDetail />
              </ProtectedRoute>
            </SuspensedView>
          }
        />
        <Route
          path='pages/userpaginate/*'
          element={
            <SuspensedView>
              <ProtectedRoute user={currentUser} validRoles={[ROLE_ROOT, ROLE_ADMINISTRATOR]}>
                <UserPaginatePage />
              </ProtectedRoute>
            </SuspensedView>
          }
        />
        <Route
          path='pages/userform/*'
          element={
            <SuspensedView>
              <ProtectedRoute user={currentUser} validRoles={[ROLE_ROOT, ROLE_ADMINISTRATOR]}>
                <UserFormPage />
              </ProtectedRoute>
            </SuspensedView>
          }
        />
        <Route
          path='pages/userprofile/*'
          element={
            <SuspensedView>
              <ProtectedRoute
                user={currentUser}
                validRoles={[ROLE_ROOT, ROLE_ADMINISTRATOR, ROLE_OPERATOR, ROLE_INSPECTOR]}
              >
                <UserProfilePage />
              </ProtectedRoute>
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return (
    <Suspense fallback={<TopBarProgress />}>
      <DisableSidebar>{children}</DisableSidebar>
    </Suspense>
  )
}

export {PrivateRoutes}
