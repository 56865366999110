import {Navigate} from 'react-router-dom'
import {UserModel} from '../modules/auth'

interface ListProps {
  user: UserModel | undefined
  validRoles: string[]
  children: JSX.Element
}

export default function ProtectedRoute({user, children, validRoles}: ListProps): JSX.Element {
  if (!user || !validRoles.includes(user.role!)) {
    return <Navigate to='/error/401' replace />
  }

  return children!
}
